<template xmlns="">
	<div id="app">
		<SubHead title="通知消息" title-img="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon_img.png"></SubHead>
		<div id="broad">
			<div id="left" class="panel">
				<div @click="setTypeId(index)" class="typeBtn op sc hand" v-for="(item,index) in typeList" :class="{ 'active':activeIndex === index }">
					{{item.name}}
					<div class="count" v-if="item.value > 0">{{item.value}}</div>
				</div>
			</div>
			<div id="right">
				<div id="top" class="panel">
					<el-checkbox class="checkbox" :indeterminate="isIndeterminate" v-model="allCheck" @change="handleCheckAllChange">全选</el-checkbox>
					<div id="readText" class="op sc hand" @click="readChecked">标为已读</div>
					<!--<div id="delText" class="op sc hand" @click="delChecked">删除</div>-->
					<div id="numberText">有<span>{{typeList[activeIndex]['value']}}</span>条系统消息未读</div>
				</div>
				<div id="data" class="panel">
					<div class="msg" v-if="msgList.length == 0">
						<div class="leftTexts">
							<div class="firstLine"><div class="text" >暂无消息</div></div>
						</div>
					</div>
					<el-checkbox-group v-model="checkMsgList" @change="handleCheckedOne">
						<div class="msg" v-for="(item,index) in msgList">
							<div class="leftTexts">
								<div class="firstLine" style="width: 900px;">
									<el-checkbox ref="checkBox" class="checkbox" style="" :label="item"  :disabled=!item.isRead >
                                        <div style="float: left;" v-if="item.user"></div>
                                    </el-checkbox>
                                    <div style="float: left; font-size: 16px;width: 670px;"><span style="color: blue;cursor: pointer;" @click="b('/circle/mySpace/' + item.uuid)">{{ item.user.nickName }}:</span>{{item.msg}}</div>
									<div class="time" >{{ item.updateTime }}</div>
								</div>
								<!--<div class="secondLine">-->
								<!--	{{item.msg}}-->
								<!--</div>-->
							</div>
							<div class="rightBtns">
								<div  class="btn op sc hand" @click="b(item.url)">查看</div>
								<!--<div class="btn op sc hand" @click="delIt(item.id,index)">删除</div>-->
								<div v-if="item.isRead == 0" class="btn disabled">已读</div>
								<div v-if="item.isRead == 1" class="btn op sc hand" @click="readIt(item.id,index)">标为已读</div>
							</div>
						</div>
					</el-checkbox-group>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import messageList from "../message/components/messageList";
import SubHead from "@/components/common/SubHead.vue";
export default {
    messsag:"",
    components: {
		SubHead,
        messageList
    },
    data() {
        return {
			allCheck:false,
			isIndeterminate: true,
	        activeIndex:0,
			msgList:[],
			checkMsgList:[],
			typeList:[
				{name:"系统通知消息",id:"0",value:0},
				{name:"问答消息",id:"13",value:0},
				{name:"商务信息",id:"14",value:0},
				{name:"钱包消息",id:"15",value:0},
				{name:"专家订单消息",id:"9",value:0},
				// {name:"店铺消息",id:"16",value:0},
			]
        }
    },

    created:function(){

    },
    mounted: function () {
		this.getMsgList();
		this.getRealNoticeNoReadNum();

    },
    methods:{
		readIt(id,index){
			this.newApi.isReadId({
				id: id
			})
			this.typeList[this.activeIndex].value --;
			this.msgList[index].isRead = 0;
		},
		readChecked(){
			for (let i = 0; i < this.checkMsgList.length; i++) {
				for (let j = 0; j < this.msgList.length; j++) {
					if(this.msgList[j].id === this.checkMsgList[i].id){
						if (this.msgList[j].isRead == 1){
							this.newApi.isReadId({
								id:this.checkMsgList[i].id
							})
							this.msgList[i].isRead = 0;
							console.log(this.msgList[i].isRead);
							this.typeList[this.activeIndex].value --;
						}

					}
				}
			}

		},
		setTypeId(index){
			this.activeIndex = index;
			this.getMsgList();
		},
		getMsgList(){
			var that = this;
			this.newApi.getNoticeList({
				type: this.typeList[this.activeIndex].id,
				page:1,
				pageSize:200
			}).then(res => {
				that.msgList = res.data;
			})
		},
		getRealNoticeNoReadNum() {
			var that = this;
			this.newApi.getRealNoticeNoReadNum({}).then(res => {
				that.typeList.forEach(item => {
					switch (item.id) {
						case "0": item.value = res.data.systemPushNum; break;
						case "13": item.value = res.data.questionAnswerNum; break;
						case "14": item.value = res.data.businessInfoNum; break;
						case "15": item.value = res.data.walletNum; break;
						case "9": item.value = res.data.orderGeneratedNum; break;
						case "16": item.value = res.data.expertOrderNum; break;
					}
			})
			})
		},
		handleCheckAllChange(val) {
			const list = this.msgList.filter(item => {
				if (item.isRead===1){
					return item;
				}
			});
			this.checkMsgList = val ? list : [];
			this.isIndeterminate = false;
		},
		handleCheckedOne(value) {
			let checkedCount = value.length;
			this.allCheck = (checkedCount === this.msgList.length);
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.msgList.length;


		}

    }
}

</script>

<style lang="scss">
	body{
		background: #f2f2f2;
	}


	#broad{
		overflow: auto;
		width: 1500px;
		margin: 70px auto;
		min-height: 600px;

		#left{
			width: 280px;
			min-height: 1000px;
			float: left;
			.typeBtn{
				width:256px;
				height:42px;
				line-height: 42px;
				color: #444444;
				font-size: 18px;
				font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
				font-weight: 500;
				text-align: center;
				margin: 12px auto;
				.count{
					position: absolute;
					background: #FF444C;
					color: #FFF;
					width: 18px;
					height: 18px;
					border-radius:50%;
					line-height: 18px;
					text-align: center;
					font-size: 10px;
					right: 18px;
					top: 11px;
				}
			}
			.typeBtn.active{
				background: #EFEFEF;
				border-radius: 5px;
			}
		}

		#right{
			float: right;
			width: 1210px;

			#top{
				height: 50px;
				.checkbox{
					zoom: 130%;
					margin: 9px 10px 9px 23px; float: left;
				}
				#text{
					height: 50px; line-height: 50px; float: left; color: #444; font-size: 18px;
				}
				#readText{
					margin-left: 20px; float: left;
					font-size: 18px; height: 50px; line-height: 50px;
					font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
					font-weight: 400;
					color: #2970FF;
				}
				#delText{
					margin-left: 20px; float: left;
					font-size: 18px; height: 50px; line-height: 50px;
					font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
					font-weight: 400;
					color: #ff0c0c;
				}
				#numberText{
					float: right;height: 50px; line-height: 50px; margin-right: 30px;
					font-size: 18px; color: #444;
					span{
						color:#2970FF;
					}
				}
			}

			#data{
				margin-top: 10px;
				overview:auto;
				padding-top: 0.1px;

				.msg{
					margin: 0px 30px 0 30px;
					padding: 10px 0;
					overflow:auto;
					border-bottom: 1px solid #dcdcdc;

					.leftTexts{
						float: left;

						.firstLine{
							overview:auto;
							height: 50px; line-height: 35px;
							.checkbox{
								margin: 0; float: left;
							}
							.time{
								float: left; font-size: 14px;
								font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
								font-weight: 400; margin-left: 20px;
								color: #999999; height: 50px; line-height: 50px;
							}
						}
						.secondLine{
							color: #666;
							font-size: 18px;
							line-height: 34px;
							height: 34px;
						}

					}
					.rightBtns{
						float: right;
						display: flex;
						gap: 20px;
						align-items: center;
						height: 50px;
						.btn.disabled{
							border:1px solid #cfcfcf;
							color: #cfcfcf;
						}
						.btn{
							height: 30px;line-height:30px;padding: 0 26px;
							border:1px solid #031F88;
							color: #031F88;
							border-radius: 5px;
							font-size: 16px;
						}
					}
				}


			}
		}

		.panel{
			background: #fff;
			border-radius: 5px;


		}





	}




	/* 设置带边框的checkbox，选中后边框的颜色 */
	.checkbox.is-bordered.is-checked {
		border-color: #2970FF;
	}

	/* 设置选中后的文字颜色 */
	.checkbox .el-checkbox__input.is-checked+.el-checkbox__label {
		color: #2970FF;
	}

	/* 设置选中后对勾框的边框和背景颜色 */
	.checkbox .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		border-color: #2970FF;
		background-color:#2970FF;
	}

	/* 设置checkbox获得焦点后，对勾框的边框颜色 */
	.checkbox .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #2970FF;
	}

	/* 设置鼠标经过对勾框，对勾框边框的颜色 */
	.checkbox .el-checkbox__inner:hover{
		border-color: #2970FF;
	}

</style>
