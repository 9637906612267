<template>
	<div id="head">
		<img id="logo" class="po sc hand" src="https://oss.baigongbao.com/2021/02/04/Cr3y7bZDaF.png" @click="a('/')">

		<div id="center">
			<img v-if="titleImg !== ''" :src="titleImg" >
			<div id="title">{{ title }}</div>
		</div>

<!--		<div id="right">-->
<!--			<el-dropdown @command="login_out" >-->
<!--				<div class="hand op sc" style="width: 100%;height: auto;display: flex;text-align: right;margin-top: 5px;" @click="circle">-->
<!--					<img :src="user.headIco" style="width: 26px;height: 26px;margin:12px 0;border-radius: 50%;margin-right: 10px;">-->
<!--					<div style="color: #FFF;height: 50px;line-height: 50px;font-size: 15px;max-width: 210px;">{{ user.nickName }}</div>-->
<!--					<div style="line-height: 50px;"><i class="el-icon-caret-bottom el-icon&#45;&#45;right" style="color: #031F88 "></i></div>-->
<!--				</div>-->
<!--				&lt;!&ndash;  下拉菜单 &ndash;&gt;-->
<!--				<el-dropdown-menu slot="dropdown" style="margin-top: -5px;">-->
<!--					<div style="width: 130px;height: 300px;background: #FFFFFF;">-->
<!--						<div class="hand op sc" style="height: 30px;width: auto;" @click="circle">-->
<!--							<div style="height: 20px;width: 20px;margin: 10px 0 0 20px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self1.png" style="height: 20px;width: 20px;">-->
<!--							</div>-->
<!--							<div style="height: 30px;width: calc(100% - 48px);float: left;line-height: 40px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;">-->
<!--								我的主页-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hand op sc" style="height: 50px;width:auto;" @click="circle">-->
<!--							<div style="height: 20px;width: 20px;margin: 23px 0 0 20px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self2.png" style="height: 20px;width: 20px;">-->
<!--							</div>-->
<!--							<div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">-->
<!--								认证中心-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hand op sc" style="width: auto;height: 30px;margin-left: 10px;" @click="a('/user/auth/index')" >-->
<!--							<div style="height: 17px;width: 17px;margin: 8px 0 0 10px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self7.png" style="height: 17px;width: 17px;">-->
<!--							</div>-->
<!--							<span style="width: calc(100% - 48px);height: auto;font-size: 14px;font-weight: bold;cursor: pointer;float: left;margin-left: 5px;margin-top: 8px;">成为专家</span>-->
<!--						</div>-->
<!--						&lt;!&ndash;                                    <div v-if="user.userType == 2" class="hand op sc" style="width: auto;height: 30px;margin-left: 10px;" @click="a('/user/auth/index')" >&ndash;&gt;-->
<!--						&lt;!&ndash;                                        <div style="height: 17px;width: 17px;margin: 8px 0 0 10px;float: left;">&ndash;&gt;-->
<!--						&lt;!&ndash;                                            <img src="~@/assets/imgs/index/self3.png" style="height: 17px;width: 17px;">&ndash;&gt;-->
<!--						&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--						&lt;!&ndash;                                        <span style="width: calc(100% - 48px);height: auto;font-size: 14px;font-weight: bold;cursor: pointer;float: left;margin-left: 5px;margin-top: 8px;">子账号管理</span>&ndash;&gt;-->
<!--						&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--						<div class="hand op sc" style="height: 50px;width:auto;" @click="a('')">-->
<!--							<div style="height: 18px;width: 18px;margin: 20px 0 0 20px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self4.png" style="height: 18px;width: 18px;">-->
<!--							</div>-->
<!--							<div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 5px;">-->
<!--								会员中心-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hand op sc" style="height: 30px;width: auto;margin-left: 10px;" @click="a('/index/help')">-->
<!--							<div style="height: 17px;width: 17px;margin: 3px 0 0 10px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self5.png" style="height: 17px;width: 17px;">-->
<!--							</div>-->
<!--							<div style="height: auto;width: calc(100% - 48px);float: left;line-height: 10px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 6px;margin-top: 7px;">-->
<!--								规则中心-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hand op sc" style="height: 30px;width:auto;" @click="a('')">-->
<!--							<div style="height: 17px;width: 17px;margin: 23px 0 0 20px;float: left;">-->
<!--								<img src="~@/assets/imgs/index/self6.png" style="height: 17px;width: 17px;">-->
<!--							</div>-->
<!--							<div style="height: auto;width: calc(100% - 48px);float: left;line-height: 50px;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">-->
<!--								帮助中心-->
<!--							</div>-->
<!--						</div>-->
<!--						<div style="width: 130px;border: 1px solid #EEEEEE;float: left;margin-top: 5px;"></div>-->
<!--						<div style="position: relative">-->
<!--							<div style="height: 30px;position: absolute;left: 20px;top: 40px;">-->
<!--								<el-dropdown-item command="/index/index">退出登录</el-dropdown-item>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-dropdown-menu>-->
<!--			</el-dropdown>-->
<!--		</div>-->
	</div>
</template>

<script>
	export default {
		name: "SubHead",
		props:{
			title:{
				type:String,
				default(){
					return ""
				}
			},
			titleImg:{
				type:String,
				default(){
					return ""
				}
			}
		},
		computed: {
			user(){
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			}
		},
		methods:{
			login_out() {
				localStorage.token = '';
				localStorage.uuid = '';
				this.$router.push({ path: '/' });
				this.$store.commit("login_out");
			},
			circle(){
				var that = this;
				if(that.user.userType === 1){
					that.utils.a('/circle/mySpace/' + that.user.uuid)
				}else if (that.user.userType === 2 || that.user.userType === 3)
					that.utils.a('/circle/organSpace/' + that.user.uuid)
			}
		}
	}
</script>

<style scoped lang="scss">
	#head{
		width: 100%;height: 60px;background: #031F88;overflow:hidden;
		position: fixed;top: 0;left: 0;z-index: 2;
		#logo{
			width: 120px;height: 38px; margin: 11px 30px;
			position: absolute;left:0;top:0;
		}
		#center{
			overflow: auto;
			width: 120px;
			margin: 0 auto;
			img{
				width: 22px;height: 18px; margin: 22px 12px;
				float: left;
			}
			#title{
				font-size: 18px;color: #FFFFFF;font-weight: bold;
				float: left;height:60px;line-height: 60px;
			}
		}
		#right{
			position: absolute;right:0;top:0;
		}
	}
</style>
