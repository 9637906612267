<template>
    <div style="width: 100%">
        <div style="width: 280px;min-height: 100vh;background: #FFFFFF;border-radius: 5px">

        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            message:[
                {type:1,name:'系统消息'},
                {type:1,name:'公告消息'},
                {type:1,name:'问答消息'},
                {type:1,name:'模板信息消息'},
                {type:1,name:'SHOP消息'},
                {type:1,name:'知识仓库消息'},
                {type:1,name:'专家连线消息'},
                {type:1,name:'钱包消息'},
            ]
        }
    },

    created:function(){

    },
    mounted: function () {


    },
    methods:{



    }
}

</script>

<style scoped>

</style>